.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  -webkit-box-align: center;
          align-items: center;
}

.justifyContentFlexEnd {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.justifyContentFlexStart {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'andreas';
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.footer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 1;
          flex: 1;
  border-top: 1px solid #F4F4F4;
  padding: 8px;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 0;
          flex: 0 0 10vh;
  box-shadow: 0 -2px 2px -2px #F4F4F4;
}

.footer > button {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 0.26316;
          flex: 0.26316;
}

.footer > a {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 0.26316;
          flex: 0.26316;
}

@media only screen and (max-height: 300px) {
  .footer {
    display: none;
  }
}

.button-footer-rules {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #00b2fe), to(#008ECB));
  background: linear-gradient(to bottom, #00b2fe 5%, #008ECB 100%);
  background-color: #00b2fe;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 10px;
  border: 0;
  border: 2px #008ECB solid;
}

.button-footer-rules > span {
  color: #FFFFFF;
}

.button-footer-rules:focus {
  outline: none;
}

.button-footer-rules:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #008ECB), to(#00b2fe));
  background: linear-gradient(to bottom, #008ECB 5%, #00b2fe 100%);
  background-color: #008ECB;
}

.button-footer-exit {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #fd923a), to(#FC7608));
  background: linear-gradient(to bottom, #fd923a 5%, #FC7608 100%);
  background-color: #fd923a;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 10px;
  border: 0;
  border: 2px #FC7608 solid;
}

.button-footer-exit > span {
  color: #FFFFFF;
}

.button-footer-exit:focus {
  outline: none;
}

.button-footer-exit:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #FC7608), to(#fd923a));
  background: linear-gradient(to bottom, #FC7608 5%, #fd923a 100%);
  background-color: #FC7608;
}

.footerButtonIcon {
  margin-right: 12px;
}

.grow {
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.grow:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.callButton {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #ff2f54), to(#FB002D));
  background: linear-gradient(to bottom, #ff2f54 5%, #FB002D 100%);
  background-color: #ff2f54;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 10px;
  border: 0;
  color: #FFFFFF;
  font-weight: bold;
  -webkit-box-pack: start;
          justify-content: flex-start;
  border: 2px #FB002D solid;
}

.callButton:focus {
  outline: none;
}

.callButton:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #FB002D), to(#ff2f54));
  background: linear-gradient(to bottom, #FB002D 5%, #ff2f54 100%);
  background-color: #FB002D;
}
