.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  -webkit-box-align: center;
          align-items: center;
}

.justifyContentFlexEnd {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.justifyContentFlexStart {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'andreas';
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.termsHeader {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 1;
          flex: 1;
  background-color: #008ECB;
  height: 10vh;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 16px;
}

.termsHeaderText {
  color: #FFFFFF;
  font-size: 25px;
  font-weight: 700;
}

.termsHeaderIcon {
  margin-left: 16px;
}

.termsContent {
  margin-top: 16px;
  margin-bottom: 64px;
  padding: 0 16px;
}

.termsContainer {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 4px;
}

.termsContainer > div {
  border: 2px solid #F4F4F4;
  padding: 0 10px;
}

.termsContainer > div > h3 {
  font-weight: bold;
}

.questionAnswer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  margin-top: 8px;
  margin-bottom: 16px;
}

.question {
  font-weight: bold;
}

.answer {
  margin-top: 8px;
}

.acceptTermsSticky {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 1;
          flex: 1;
  background-color: #2593BA;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  position: absolute;
  bottom: 10vh;
  left: 0;
  right: 0;
  padding: 8px 32px 8px 16px;
}

.acceptTermsSticky > span {
  color: #FFFFFF;
}

.checkboxAgreesToTerms {
  width: 20px;
  height: 30px;
}

.termsFromHtmlContainer {
  line-height: 1.3;
}

.termsFromHtmlContainer > h1 {
  padding-left: 0;
}

.termsFromHtmlContainer > h1 {
  padding-left: 0;
}
