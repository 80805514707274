.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  -webkit-box-align: center;
          align-items: center;
}

.justifyContentFlexEnd {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.justifyContentFlexStart {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'andreas';
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.loginScreen {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  height: 100%;
}

.loginScreen > div {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
}

.titleContainer {
  padding-left: 8px;
}

.loginForm {
  padding-left: 32px;
  padding-right: 32px;
}

.loginScreenTextInput {
  border-radius: 24px;
  border: 4px solid #C4C4C4;
  padding: 16px 8px 16px 8px;
  width: 100%;
}

.focusedTitle {
  color: #008ECB;
}

.focusedInput {
  border: 4px solid #008ECB !important;
}

.ageCircleOuter {
  padding: 10px;
  display: inline-block;
  border-radius: 50%;
  border: 5px #BDBDBD solid;
  width: 60px;
  height: 60px;
  margin-right: 4px;
  margin-bottom: 8px;
}

.selected {
  border: 5px #008ECB solid;
}

.ageCircleInner {
  padding: 10px;
  margin: 20px;
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  top: -25px;
  left: -25px;
  text-align: center;
  vertical-align: middle;
  background-color: #F4F4F4;
}

.ageCircleInner > span {
  position: relative;
  top: 2px;
  left: -1px;
  font-size: 18px;
}

.circlesContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 0.25;
          flex: 0.25;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.button-login {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #00b2fe), to(#008ECB));
  background: linear-gradient(to bottom, #00b2fe 5%, #008ECB 100%);
  background-color: #00b2fe;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 20px;
  padding: 13px 32px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #3d768a;
  padding: 8px;
  max-width: 240px;
  width: 100%;
  margin: auto;
  border-radius: 15px;
  border: 4px #008ECB solid;
}

.button-login:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #008ECB), to(#00b2fe));
  background: linear-gradient(to bottom, #008ECB 5%, #00b2fe 100%);
  background-color: #008ECB;
}

.button-login:active {
  position: relative;
  top: 1px;
}

.button-login:focus {
  outline: none;
}

.button-login > span {
  font-size: 25px;
  font-weight: bold;
}

.button-login-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  -webkit-box-pack: center;
          justify-content: center;
  border-top: 6px #F4F4F4 solid;
  margin-top: 8px;
}

.errorMessage {
  text-align: center;
  color: red;
}
