.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  -webkit-box-align: center;
          align-items: center;
}

.justifyContentFlexEnd {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.justifyContentFlexStart {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'andreas';
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.waitingMessage {
  padding: 8px;
  margin: 16px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
}

.waitingMessageStartsSoon {
  background-color: #25B73D;
  border: 10px solid #18B427;
  border-radius: 10px;
  color: #FFFFFF;
}

.waitingMessageWaiting {
  background-color: #F9EE5B;
  border: 10px solid #F6E618;
  border-radius: 10px;
  color: #000000;
}

.waitingMessageNotAvailable {
  background-color: #FC7608;
  border: 10px solid #DB6804;
  border-radius: 10px;
  color: #FFFFFF;
}

.somethingToDoHeader {
  margin-top: 32px;
  padding: 0 24px;
}

.categoriesChooser {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 1;
          flex: 1;
  justify-content: space-around;
}

.categoryBox {
  border: 1px solid #C4C4C4;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.thingSelected {
  border: 2px solid #2593BA;
}

.pagesThingsToDoContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  margin: 24px;
}

.singleThingToDo {
  border: 2px #F4F4F4 solid;
  padding: 16px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  border-radius: 10px;
  margin-bottom: 16px;
}

.singleThingToDo img {
  width: 100% !important;
}

.thingsToDoScreenContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
}

.thingToDoOverviewContainer {
  line-height: 1.3;
}

.thingToDoOverviewContainer > h1 {
  padding-left: 0;
}
