.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  -webkit-box-align: center;
          align-items: center;
}

.justifyContentFlexEnd {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.justifyContentFlexStart {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'andreas';
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.header {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 1;
          flex: 1;
  padding: 0 16px;
  background-color: #FFFFFF;
  border-radius: 8px;
  -webkit-box-flex: 0;
          flex: 0 0 48px;
  -webkit-box-align: center;
          align-items: center;
  border-bottom: 2px #F4F4F4 solid;
  box-shadow: 0 1px 1px -1px #333;
}

.header > div {
  -webkit-box-flex: 1;
          flex: 1;
}

.headerTelephone {
  margin-left: 6.4px;
  color: #008ECB;
}

.headerTitle {
  align-self: center;
}

.headerTitle > span {
  font-size: 16px;
}
