.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  -webkit-box-align: center;
          align-items: center;
}

.justifyContentFlexEnd {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.justifyContentFlexStart {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'andreas';
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.titleValueCard {
  border: 2px solid #F4F4F4;
  padding: 0 10px;
}

.titleValueCard > h1 {
  padding-left: 0;
}
