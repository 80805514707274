.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  -webkit-box-align: center;
          align-items: center;
}

.justifyContentFlexEnd {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.justifyContentFlexStart {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'andreas';
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.chatHomeScreen {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
}

.chatStartBoxAfterHeaderContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 1;
          flex: 1;
  padding: 0 16px;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.startChatBoxContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  width: 40%;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  margin-right: 8px;
}

.startChatBoxContainer > h1 {
  color: #008ECB;
}

.whatIsChatBox {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  width: 40%;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
}

.textOverlayWhatIsChatBox {
  color: #008ECB;
  position: absolute;
  padding: 0;
  top: 10%;
  left: 20%;
  z-index: 4;
}

.iconInWhatIsChatBox {
  position: absolute;
  padding: 0;
  top: 10%;
  left: 30%;
  z-index: 1;
}

.thingsToDoContainer {
  margin-top: 32px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 1;
          flex: 1;
  text-align: center;
}

.thingToDo {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  justify-content: space-around;
  -webkit-box-align: center;
          align-items: center;
  display: inline-block;
}

.thingToDo > img {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  width: 90%;
  display: inline-block;
  -webkit-box-align: center;
          align-items: center;
}

.thingToDo > div {
  display: inline-block;
  text-align: center;
  margin-top: 16px;
  width: 60%;
}

.thingToDo > div > span {
  font-weight: bold;
  font-size: 16px;
  -webkit-box-align: center;
          align-items: center;
  width: 40%;
}

.chatSubjectTextInput {
  border-radius: 24px;
  border: 4px solid #C4C4C4;
  padding: 16px 8px 16px 8px;
  width: 100%;
  max-width: 350px;
  margin-top: 8px;
}

.button-start-chat {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #00b2fe), to(#008ECB));
  background: linear-gradient(to bottom, #00b2fe 5%, #008ECB 100%);
  background-color: #00b2fe;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 20px;
  padding: 13px 32px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #3d768a;
  margin: auto;
  border-radius: 15px;
  border: 4px #008ECB solid;
}

.button-start-chat:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #008ECB), to(#00b2fe));
  background: linear-gradient(to bottom, #008ECB 5%, #00b2fe 100%);
  background-color: #008ECB;
}

.button-start-chat:active {
  position: relative;
  top: 1px;
}

.button-start-chat:focus {
  outline: none;
}

.button-start-chat > span {
  font-size: 25px;
  font-weight: bold;
}

.button-start-chat-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-flex: 1;
          flex: 1;
  -webkit-box-pack: center;
          justify-content: center;
  margin-top: 8px;
}
