.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  -webkit-box-align: center;
          align-items: center;
}

.justifyContentFlexEnd {
  -webkit-box-pack: end;
          justify-content: flex-end;
}

.justifyContentFlexStart {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'andreas';
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.chatHeaderTimeRemaining {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 1;
          flex: 1;
  height: 5vh;
  position: absolute;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 100;
  top: 48px;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  padding: 16px 8px;
  border-bottom: 1px solid #BDBDBD;
  background-color: #FFFFFF;
}

.clock {
  align-self: center;
  color: #2593BA;
  margin-right: 4px;
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
}

.clockIcon {
  margin-right: 2px;
  margin-bottom: 2px;
}

.timeLeft {
  color: #2593BA;
  font-size: 18px;
}

.chatContent {
  padding: 0 8px;
  margin-top: 10.2vh;
}

.writeMessageContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-flex: 1;
          flex: 1;
  height: 8vh;
  position: absolute;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 100;
  bottom: 10vh;
  padding: 0 8px;
  background-color: #FFFFFF;
  -webkit-box-align: center;
          align-items: center;
  border-top: 1px solid #BDBDBD;
  height: auto;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.chatTextInput {
  width: 90%;
  box-sizing: border-box;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  resize: none;
  line-height: 18px;
  overflow: auto;
  height: auto;
  padding: 4px 8px;
  margin: 8px 0;
}

.chatTextInput::-webkit-input-placeholder {
  color: gainsboro;
}

.chatTextInput::-moz-placeholder {
  color: gainsboro;
}

.chatTextInput:-ms-input-placeholder {
  color: gainsboro;
}

.chatTextInput::-ms-input-placeholder {
  color: gainsboro;
}

.chatTextInput::placeholder {
  color: gainsboro;
}

.chatTextInput:focus {
  outline: none;
}

.chatTextInputFocused {
  border: 2px solid #008ECB;
}

.chatContentContainer {
  margin-bottom: 10vh;
}
